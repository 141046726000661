import './HomeHero.scss';
import {gsap} from 'gsap';
import {useEffect} from 'react';

function ContactHero() {

  let tlHeader = gsap.timeline({ defaults: { ease: "power3InOut" }});

  useEffect(() => {
    tlHeader.to('.is-appear', {opacity: 1, duration: .6, y: 0, delay: 1, stagger: .2 })
  })

  return (
    <div className='header-hero'>
      <h1 className='header-title'> <span className='text-red is-appear'>CONTACT ME</span> </h1>
      <a href="mailto: contact@guillaume-sauteur.ch">
      <div className="contact-button is-appear is-moused">
        <span className='text-red'>➔</span>contact@guillaume-sauteur.ch
        <div className="contact-button-overlay"></div> 
      </div>
      </a>
    </div>
  );
}

export default ContactHero;

